import * as React from 'react';
import StylishThumbs from '../../../components/StylishThumbs';
import { useProductsQueryDataWithFluid } from '../../../hooks/productsQueryFluid';
import styled from './index.module.css';

const Products = () => {
  const { edges } = useProductsQueryDataWithFluid();
  return (
    <div className={styled.wrapper}>
      {edges.map((edge, idx) => (
        <div key={`productNr${idx}`} className={styled[`indexProduct${(idx % 2) + 1}`]}>
          <StylishThumbs edge={edge} idx={idx} />
        </div>
      ))}
    </div>
  );
};

export default Products;
