import { useStaticQuery, graphql } from 'gatsby';

export const useProductsQueryDataWithFluid = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { layout: { eq: "product" } } }) {
        edges {
          node {
            html
            fields {
              slug
            }
            frontmatter {
              title
              image
            }
          }
        }
      }
    }
  `);
  return allMarkdownRemark;
};
